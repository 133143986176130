@import "~@ng-select/ng-select/themes/default.theme.css";

body {
    background-color: whitesmoke !important;
}

.no-data-available {
    text-align: center;
}

.dataTables_empty {
    display: none;
}

.dataTables_length select {
    z-index: 2 !important;
}

.modal-backdrop.show {
    opacity: 0.4;
    transition-duration: 400ms;
    z-index: -1 !important;
}

/* You can add global styles to this file, and also import other style files */

.error {
    color: red;
}


.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.r-icon{
    pointer-events:none;
}


.r-button{
    padding: 5px !important;
    width: 30px !important;
}

.r-white{
    color: white !important;
}
/* .navbar-white{
    background-color : red !important;
}

[class*=sidebar-dark-] {
    background-color: red !important;
} */


/* deepak changes start */


/* .navbar-brand {
    background: indigo;
    padding-left: 10px;
    padding-right: 10px;
}
.sidebar {
    background : red !important;
}
.mainpagewrap .main-container .main-container-before {
    background : red !important;
}

.sidebar .list-group  a.list-group-item {
    background : red !important;
    color: #fff !important;
}
.sidebar .list-group a.router-link-active, .nested-menu .expand ul.submenu li a.router-link-active {
    background : green !important;
} */


/* main-header navbar navbar-expand navbar-dark navbar-secondary */


/* deepak changes end */
