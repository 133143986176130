/*
Author       : Deepak Shinde
Template Name: Global-India Insurance Broker's Pvt. Ltd.
Version      : 1.1
*/

#recaptcha_switch_audio {
    display: none;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/MaterialIcons-Regular.eot);
    /* For IE6-8 */
    src: local('Material Icons'), local('../fonts/MaterialIcons-Regular'), url(../fonts/MaterialIcons-Regular.woff2) format('woff2'), url(../fonts/MaterialIcons-Regular.woff) format('woff'), url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}


/*-----------------
	General
-----------------*/

html {
    height: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: .8rem;
    color: #52525a;
    background-color: #f7f7f7;
    min-height: 100%;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat';
    margin-top: 0;
    font-weight: 500;
}

h1,
.h1 {
    font-size: 2.25rem;
}

h2,
.h2 {
    font-size: 1.875rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.125rem;
}

h5,
.h5 {
    font-size: 0.875rem;
}

h6,
.h6 {
    font-size: 0.75rem;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}

hr {
    border-top: 1px solid #d6d6d6;
}

dl,
ol,
ul {
    margin-left: 0;
    padding: 0;
    list-style: none;
}

input,
button,
a {
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

input,
input:focus,
button,
button:focus {
    outline: none;
}

input[type="file"] {
    height: auto;
}

input[type=text],
input[type=password] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea.form-control {
    resize: vertical;
}

label.col-form-label {
    font-weight: 600;
    line-height: 1.2;
}

section {
    padding: 20px 0;
}

body::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #acacac;
}


/*-----------------
	Preloader CSS
-----------------------*/

div#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: rgba(255, 255, 255, .6);
}

.preeloader {
    background: rgba(255, 255, 255, .6) none repeat scroll 0 0;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 999999;
}

.preeloader {
    /*background: rgba(255, 255, 255, .6) none repeat scroll 0 0;*/
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 999999;
    background-color: rgba(255, 255, 255, .6);
    background-image: url(../img/loader-2.gif);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60px 60px;
}


/*.preloader-spinner {
    -webkit-animation: 1s ease-out 0s normal none infinite running pulsate;
    animation: 1s ease-out 0s normal none infinite running pulsate;
    border: 10px solid #fcb040;
    border-radius: 40px;
    display: block;
    height: 40px;
    left: 50%;
    margin: -20px 0 0 -20px;
    opacity: 0;
    position: fixed;
    top: 50%;
    width: 40px;
    z-index: 10;
}

@-webkit-keyframes pulsate {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes pulsate {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}*/

body.fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
}

.form-control,
.ui.form input.form-control {
    border-color: #a5a5a5;
    color: #52525e;
    box-shadow: none;
    font-size: 0.8rem;
    height: 50px;
    font-size: 1rem;
    border-radius: 10px;
    /*font-weight: 600;*/
}

.form-control:focus,
.ui.form input.form-control:focus {
    border-color: #ccc;
    box-shadow: none;
    outline: 0 none;
}

.form-control[disabled],
fieldset[disabled] .form-control,
.ui.form input.form-control[disabled],
.ui.form fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #f9f9f9;
}

.required label.col-form-label:after {
    content: "*";
    color: red;
    padding: 0 2px;
}

label.col-form-label span.text-muted {
    font-size: 0.7rem;
    font-weight: 400;
}

.navbar-nav>li {
    float: left;
}

.form-control-lg.form-control {
    border-radius: 4px;
    height: 46px;
}

.form-control-sm.form-control {
    height: 30px;
    /*border-radius: 0;*/
}

.input-group.input-group-lg .form-control {
    height: 46px;
}

.input-group-text {
    background-color: #f7f7f7;
    border-color: #a5a5a5;
}

select.form-control[multiple] option,
select.form-control[size] option {
    border-radius: 3px;
    padding: 7px 12px;
}

select.form-control[multiple],
select.form-control[size] {
    height: 200px;
    padding: 7px;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.bg-black {
    background-color: #000 !important;
}

.bg-dark-gray {
    background-color: #53565a !important;
}

.bg-light-gray {
    background-color: #f5f5f5 !important;
}

.bg-red {
    background-color: #ff6600 !important;
}


/*Brand Color*/

.bg-brand-yellow {
    background-color: #fcb040;
}

.bg-brand-blue {
    background-color: #25aae1;
}

.bg-brand-red {
    background-color: #ef4036;
}

.bg-brand-green {
    background-color: #39b54a;
}

.text-brand-yellow {
    color: #fcb040;
}

.text-brand-blue {
    color: #25aae1;
}

.text-brand-red {
    color: #ef4036;
}

.text-brand-green {
    color: #39b54a;
}


/*-----------------
	Table
-----------------------*/

.table {
    color: #333;
}

.table.table-white {
    background-color: var(--white);
}

.table thead th {
    font-weight: 500;
    /*letter-spacing: 0.05em;*/
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
    background-color: #f6f6f6;
}

table.table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
}

table.table td h2.table-avatar.blue-link a {
    color: #007bff;
}

table.table td h2 a {
    color: #333;
}

table.table td h2 a:hover {
    color: #ff9b44;
}

table.table td h2 span {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 3px;
}

table.dataTable {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}

.table-nowrap td,
.table-nowrap th {
    white-space: nowrap
}

.table-hover tbody tr:hover {
    background-color: #f7f7f7;
    color: #212529;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 30px !important;
}

table.dataTable thead>tr>th {
    font-weight: 600;
}

.custom-table tr {
    background-color: #fff;
    box-shadow: 0 0 3px #e5e5e5;
}

.table.custom-table>tbody>tr>td,
.table.custom-table>tbody>tr>th,
.table.custom-table>tfoot>tr>td,
.table.custom-table>tfoot>tr>th,
.table.custom-table>thead>tr>td,
.table.custom-table>thead>tr>th {
    padding: 10px 8px;
    vertical-align: middle;
}

.table.custom-table>tbody>tr>td {
    padding: 5px 8px;
}

.table.custom-table-sm>tbody>tr>td {
    padding: 3px 5px;
}

.table.custom-table>tbody>tr>td:first-child,
.table.custom-table>thead>tr>th:first-child {
    padding-left: 15px;
}

.table.custom-table>tbody>tr>td:last-child,
.table.custom-table>thead>tr>th:last-child {
    padding-right: 15px;
}

.action-icon {
    color: #777;
    font-size: 18px;
    display: inline-block;
}

.table .dropdown-menu {
    font-size: 13px;
    min-width: 120px;
    padding: 0;
}

.table .dropdown-menu .dropdown-item {
    padding: 8px 10px;
    font-size: .7rem;
}

.dropdown-action .dropdown-toggle::after {
    display: none;
}

.table.custom-table.srn th:first-child,
.table.custom-table.srn td:first-child {
    max-width: 40px !important;
    width: 40px !important;
}

.table.custom-table tbody tr td.action-btns,
.table.custom-table.action-table tbody tr td:last-child {
    /*min-width: 150px;*/
    max-width: 200px !important;
    width: 200px !important;
}

.table.custom-table tbody tr td.action-btns .btn,
.table.custom-table.action-table tbody tr td:last-child .btn {
    font-size: .6rem;
    padding: .15rem .50rem;
    margin: .1rem;
}

.table.custom-table tbody tr td .badge {
    font-size: 100%;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 !important;
    margin-left: -2px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: none !important;
    border-color: transparent;
    box-shadow: none;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting,
table.dataTable thead .sorting_desc {
    background-image: none !important;
}

table.dataTable thead th,
table.dataTable thead td {
    border-bottom: 2px solid #dee2e6 !important;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #dee2e6 !important;
}


/*-----------------
	Helper Class
-----------------------*/

.actionbtn {
    background-color: #39b54a;
    border: 1px solid #39b54a;
    border-radius: 24px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    padding: 0 7px;
    /*width: 100%;
	max-width: 40px;*/
    display: inline-block;
}

.actionbtn i {
    margin-right: 3px;
}

.actionbtn:hover {
    background-color: #fff;
    color: #39b54a;
    border: 1px solid #39b54a;
    text-decoration: none;
}

.edit-btn:after {
    content: 'Edit';
    padding: 0 1px;
}

.delete-icon {
    color: #e30b0b;
    float: right;
    font-size: 18px;
}

.delete-icon:hover {
    color: #e30b0b;
}

.ul-list {
    list-style: disc;
    margin-left: 1rem;
}

.ol-list {
    list-style: decimal;
    margin-left: 1rem;
}

.cal-icon {
    position: relative;
    width: 100%;
}

.cal-icon input.form-control {
    position: relative !important;
    z-index: 2 !important;
    background-color: transparent !important;
    cursor: pointer !important;
}

.cal-icon:before {
    color: #979797;
    content: "\f073";
    display: block;
    font-family: "FontAwesome";
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 10px;
    top: 12px;
}

.time-icon:before {
    content: "\f017";
}

.form-focus .cal-icon:before {
    top: 18px;
}

.blue-gradient {
    background-color: #25aae1;
    background: linear-gradient( #25aae1, #157fac);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-color: #25aae1;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}

.yellow-gradient {
    background-color: #fcb040;
    background: linear-gradient( #fcb040, #c88726);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-color: #fcb040;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}


/*-----------------
	Bootstrap Classes
-----------------------*/

@media (min-width: 1366px) {
    .container {
        max-width: 1280px;
    }
}

.btn {
    border-radius: 10px;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
}

.btn-white {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #000;
}

.btn-white:focus,
.btn-white:hover {
    background-color: #f7f7f7;
}

.rounded,
.circle {
    border-radius: 500px;
}

.bg-purple,
.badge-purple {
    background-color: #7460ee !important;
}

.bg-primary,
.badge-primary {
    background-color: #f12a00 !important;
}

.bg-success,
.badge-success {
    background-color: #55ce63 !important;
}

.bg-info,
.badge-info {
    background-color: #009efb !important;
}

.bg-warning,
.badge-warning {
    background-color: #ffbc34 !important;
}

.bg-danger,
.badge-danger {
    background-color: #f62d51 !important;
}

.text-primary,
.dropdown-menu>li>a.text-primary {
    color: #f12a00 !important;
}


/*.text-success,
.dropdown-menu>li>a.text-success {
	color: #55ce63 !important;
}
*/


/*.text-danger,
.dropdown-menu>li>a.text-danger {
	color: #f62d51 !important;
}*/

.text-info,
.dropdown-menu>li>a.text-info {
    color: #009efb !important;
}

.text-warning,
.dropdown-menu>li>a.text-warning {
    color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu>li>a.text-purple {
    color: #7460ee !important;
}

.btn-purple {
    background-color: #7460ee;
    border: 1px solid #7460ee;
    color: #fff;
}

.btn-purple:hover,
.btn-purple:focus {
    background-color: #482fe9;
    border: 1px solid #482fe9;
}

.btn-primary {
    background-color: #1a1446;
    color: #fff;
    border: none !important;
    /*border-radius: 30px;*/
    font-size: 0.8rem;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
    background-color: #1a1446;
    border: 1px solid #1a1446;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    background-color: #ff851a;
    border: 1px solid #ff851a;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
    background-color: #ff851a;
    border-color: #ff851a;
    color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #f12a00;
    border-color: #f12a00;
    color: #fff;
}


/*.btn-success {
	background-color: #55ce63;
	border: 1px solid #55ce63
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
	background: #4ab657;
	border: 1px solid #4ab657;
	color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
	background: #4ab657;
	border: 1px solid #4ab657
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
	background-color: #55ce63;
	border-color: #55ce63;
	color: #fff;
}*/

.btn-info {
    background-color: #009efb;
    border: 1px solid #009efb
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}

.btn-warning {
    background: #ffbc34;
    border: 1px solid #ffbc34
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
    background: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    background: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}


/*.btn-danger {
	background: #f62d51;
	border: 1px solid #f62d51
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
	background-color: #e6294b;
	border: 1px solid #e6294b
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
	background: #e9ab2e;
	border: 1px solid #e9ab2e
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #f62d51;
	border-color: #f62d51;
	color: #fff;
}*/

.btn-custom {
    font-size: .9rem;
    line-height: 1.5;
    padding: 10px 30px;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 10px;
    transition: all .3s;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.btn-custom.btn-lg {
    padding: 15px 35px;
    font-size: 1.2rem;
}

.btn-custom.btn-sm {
    padding: 5px 20px;
    font-size: 1.2rem;
    font-size: .7rem;
}

.btn-custom.h50 {
    height: 50px;
}

.btn-custom.h40 {
    height: 40px;
}

.btn:hover,
.btn:focus {
    transition: all .3s;
}

.btn.yellow {
    color: #fff;
    background-color: #fcb040;
}

.btn.yellow:hover {
    color: #fcb040;
    border: solid 1px #fcb040;
    background-color: #fff;
}

.btn.blue {
    color: #fff;
    background-color: #25aae1;
}

.btn.blue:hover {
    color: #25aae1;
    border: solid 1px #25aae1;
    background-color: #fff;
}

.btn.red {
    color: #fff;
    background-color: #ef4036;
}

.btn.red:hover {
    color: #ef4036;
    border: solid 1px #ef4036;
    background-color: #fff;
}

.btn.green {
    color: #fff;
    background-color: #39b54a;
}

.btn.green:hover {
    color: #39b54a;
    border: solid 1px #39b54a;
    background-color: #fff;
}


/*.btn-custom-secondary {
	background-color: #000;
	color: #fff;
}

.btn-custom-secondary.focus,
.btn-custom-secondary:focus,
.btn-custom-secondary:hover {
	background-color: #f12a00;
	color: #fff;
}
*/

.btn-whfull {
    width: 100%;
    height: 40px;
    line-height: 1.5;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #0c4a85;
    border-color: #0c4a85;
}

.pagination>li>a,
.pagination>li>span {
    color: #0c4a85;
}

.page-link:hover {
    color: #0c4a85;
}

.page-link:focus {
    box-shadow: unset;
}

.page-item.active .page-link {
    background-color: #0c4a85;
    border-color: #0c4a85;
}

.dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transform-origin: left top 0;
    box-shadow: inherit;
    background-color: #fff;
}

.navbar-nav .open .dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
    font-size: 13px;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    box-shadow: none;
}

.btn-link {
    color: #000;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.form-text {
    color: #999;
    font-size: 12px;
    margin-bottom: 7px;
    margin-top: 7px;
}

.btn.focus,
.btn:focus {
    box-shadow: unset;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #1a1446;
}

.list-group-item {
    border: 1px solid #a5a5a5;
}

.custom-file {
    height: 40px;
}

.custom-file .custom-file-label {
    border: 1px solid #a5a5a5;
    height: 40px;
    margin-bottom: 0;
    padding: 10px 15px;
    line-height: 1;
}

.custom-file-label::after {
    background-color: #f2f2f2;
    height: 38px;
    padding: 10px 15px;
    line-height: 1;
}

.custom-file input[type="file"] {
    height: 40px;
}

.custom-control-label::before,
.custom-control-label::after {
    top: .12rem;
}

.bg-inverse-primary {
    background: rgba(77, 138, 240, .2) !important;
    color: #4d8af0 !important;
}

.bg-inverse-secondary {
    background: rgba(194, 198, 209, .2) !important;
    color: #c2c6d1 !important;
}

.bg-inverse-success {
    background-color: rgba(15, 183, 107, 0.12) !important;
    color: #26af48 !important;
}

.bg-inverse-purple {
    background: rgba(108, 97, 246, .2) !important;
    color: #6c61f6 !important;
}

.bg-inverse-warning {
    background-color: rgba(255, 152, 0, 0.12) !important;
    color: #f39c12 !important;
}

.bg-inverse-danger {
    background-color: rgba(242, 17, 54, 0.12) !important;
    color: #e63c3c !important;
}

.bg-inverse-light {
    background: rgba(223, 223, 223, .2) !important;
    color: #dfdfdf !important;
}

.bg-inverse-dark {
    background: rgba(116, 118, 123, .2) !important;
    color: #74767b !important;
}

.bg-inverse-white {
    background: rgba(255, 255, 255, .2) !important;
    color: #fff !important;
}

.bg-inverse-info {
    background-color: rgba(2, 182, 179, 0.12) !important;
    color: #1db9aa !important;
}

.bg-inverse-default {
    background-color: rgba(40, 52, 71, 0.12) !important;
    color: #283447 !important;
}

.bg-gradient-primary {
    background: linear-gradient( to right, #6e00ff 10%, #6e00ff 100%);
}

.bg-gradient-secondary {
    background: linear-gradient(to bottom right, #f35c38 0, #f35c38 100%);
}

.bg-gradient-success {
    background: linear-gradient(to bottom right, #0ba408 0, #28e224 100%);
}

.bg-gradient-info {
    background: linear-gradient(to bottom right, #69c7de 0, #1e9fc4 100%);
}

.bg-gradient-warning {
    background: linear-gradient(to bottom right, #ecd53e 0, #efaf28 100%)
}

.bg-gradient-teal {
    background: linear-gradient(to bottom right, #00796b 0, #4db6ac 100%);
}

.bg-gradient-blue {
    background: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}

.bg-gradient-danger {
    background: linear-gradient(to right, #f95058 0, #f33540 100%);
}

.bg-gradient-purple {
    background: linear-gradient(to right, #8a56e6 0, #6f3cca 100%);
}

.text-muted {
    color: #3a3a3a!important;
}

.text-muted.white {
    color: #114984!important
}

label.label {
    font-size: .8rem;
    font-weight: 600;
}

.form-group {
    margin-bottom: 1.5rem;
}

label.req:after {
    position: relative;
    content: '*';
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: .8rem;
    top: -3px;
    right: 0;
    width: 0;
    height: 0;
    color: #f12a00;
}

.form-focus label.req:after {
    position: absolute;
    top: -2px;
}


/*label.req:after {}*/

.form-focus.focused .focus-label.req,
.form-focus .form-control:focus~.focus-label.req,
.form-focus .form-control:-webkit-autofill~.focus-label.req,
.form-focus.select-focus .focus-label.req {
    padding-right: 8px;
}

.form-focus.focused .focus-label.req:after,
.form-focus .form-control:focus~.focus-label.req:after,
.form-focus .form-control:-webkit-autofill~.focus-label.req:after,
.form-focus.select-focus .focus-label.req:after {
    right: 7px;
}


/*-----------------
	Semantic Css
-----------------------*/

.ui.checkbox label {
    font-weight: 600;
    cursor: pointer;
    font-size: .9rem;
}

.ui.form .label {
    margin: 0 0 .28571429rem 0;
    color: rgba(0, 0, 0, .87);
    font-size: .92857143em;
    font-weight: 600;
    text-transform: none;
}

.ui.form label.ui.label {
    padding: .3em .8em;
    border: 1px solid #39b54a;
    color: #fff;
    background-color: transparent;
    font-size: .750rem;
    background-color: #39b54a;
}


/*i.flag:not(.icon):before {
	background-image: url(../img/flags/flags.png);
	background-repeat: no-repeat;
}*/


/*.quotes-editoption .option-box .ui.checkbox label {
	color: #fff;
}*/

.ui.multiple.dropdown {
    padding: .67857143em 2.1em .67857143em 1em;
}

.ui.search.dropdown>.text {
    left: 0;
    line-height: 1.5;
    font-size: .8rem;
}

.ui.checkbox .box:before,
.ui.checkbox label:before,
.ui.selection.dropdown {
    border-color: #a5a5a5;
}

.ui.selection.dropdown {
    border-radius: 10px;
}

.ui.checkbox label,
.ui.checkbox+label {
    color: #52525e;
    margin-bottom: 0;
}

.card-header .ui.checkbox label,
.card-header .ui.checkbox+label {
    color: #fff !important;
}

.ui.dropdown .menu>.item {
    font-size: .8rem;
}

.ui.ribbon.label {
    left: calc(-1rem - 1.5em);
    margin-right: -1.5em;
    padding-left: calc(1rem + 1.5em);
    padding-right: 1.5em;
}

.ui.red.ribbon.label {
    border-color: #39b54a!important;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #28a745 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #28a745 !important;
}

.ui.checkbox input:checked~.box:before,
.ui.checkbox input:checked~label:before {
    border-color: #28a745 !important;
}

.ui.form select {
    border-radius: 10px;
    height: 50px;
    font-size: .9rem;
    border-color: #a5a5a5;
}

.ng-select.ng-select-single .ng-select-container {
    height: 50px !important;
    border-color: #a5a5a5;
}


/*-----------------
	Animation
-----------------------*/

@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes taskHighlighter {
    0% {
        background: #FEEB99;
    }
    100% {
        background: #fff;
    }
}

@keyframes taskHighlighter {
    0% {
        background: #FEEB99;
    }
    100% {
        background: #fff;
    }
}


/*-----------------
	Toggle Button
-----------------------*/

.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}

.checktoggle:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}

.check:checked+.checktoggle {
    background-color: #55ce63;
}

.check:checked+.checktoggle:after {
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
}

.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0;
}

.onoffswitch-inner {
    display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}

.onoffswitch-inner:before {
    background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}

.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
    height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0px;
}


/*-----------------
	Modal
-----------------------*/

.modal {
    -webkit-overflow-scrolling: touch;
}

.modal-footer.text-center {
    justify-content: center;
}

.modal-footer.text-left {
    justify-content: flex-start;
}

.modal-dialog.modal-md {
    max-width: 600px;
}

.custom-modal .modal-content {
    border: 0;
    border-radius: 10px;
    overflow: hidden;
}

.custom-modal .modal-footer {
    border: 0;
}

.custom-modal .modal-header {
    border: 0;
    justify-content: center;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.custom-modal .modal-footer {
    border: 0;
    justify-content: center;
    padding: 0 30px 30px;
}

.custom-modal .modal-body {
    padding: 15px;
}

.custom-modal .close {
    background-color: #555;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 12px;
    width: 20px;
    z-index: 99;
}

.custom-modal .modal-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #114984;
    line-height: 1.2;
}

.modal-backdrop.show {
    opacity: 0.4;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
}

.modal .card {
    box-shadow: unset;
}

.custom-modal .modal-body .cst-checkbox {
    font-size: 1rem;
}

.submit-section {
    text-align: center;
    margin-top: 20px;
}

.submit-btn {
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

.swal2-popup,
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
    border-radius: 10px !important;
}


/*.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
	font-size: .9rem !important;
    line-height: 1.5 !important;
    padding: 10px 30px !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    border-radius: 10px !important;
    transition: all .3s !important;
    font-family: "Montserrat",sans-serif !important;
    font-weight: 500 !important;
}
.swal2-styled.swal2-confirm {
    background-color: #39b54a !important;
    border: solid 1px #39b54a !important;
}
.swal2-styled.swal2-confirm:hover {
    color: #39b54a !important;
    background-color: #fff !important;
}
.swal2-styled.swal2-cancel {
	background-color:#f12a00 !important;
	border: solid 1px #f12a00 !important;
}
.swal2-styled.swal2-cancel:hover {
    color: #f12a00 !important;
    background-color: #fff !important;
}*/


/*-----------------
	Select2
-----------------------*/

.select2-container .select2-selection--single {
    border: 1px solid #a5a5a5;
    border-radius: 0.25rem;
    height: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 28px;
    right: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #ccc transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    height: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #ccc;
    border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
    padding-left: 15px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #495057;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 28px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #1a1446;
}


/*-----------------
	Focus Label
-----------------------*/

.form-focus {
    height: 50px;
    position: relative;
}

.form-focus .focus-label {
    font-size: .8rem;
    font-weight: 400 !important;
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate3d(0, 25px, 0) scale(1);
    -ms-transform: translate3d(0, 25px, 0) scale(1);
    -o-transform: translate3d(0, 25px, 0) scale(1);
    transform: translate3d(0, 25px, 0) scale(1);
    transform-origin: left top;
    transition: 240ms;
    left: 12px;
    top: -8px;
    z-index: 1;
    color: #333;
    margin-bottom: 0;
}

.form-focus.focused .focus-label {
    opacity: 1;
    font-weight: 500;
    top: -32px;
    font-size: .7rem;
    line-height: 1.4;
    z-index: 2;
    background-color: #f9f9f9;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 5px;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
}

.form-focus .form-control:focus~.focus-label,
.form-focus .form-control:-webkit-autofill~.focus-label {
    opacity: 1;
    font-weight: 500;
    top: -32px;
    font-size: .7rem;
    line-height: 1.4;
    z-index: 2;
    background-color: #f9f9f9;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 5px;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
}

.form-focus .form-control,
.ui.form .form-focus input[type=text].form-control {
    height: 50px;
    padding: 12px 12px 6px;
    font-size: 1rem;
}

.form-focus .form-control::-webkit-input-placeholder {
    color: transparent;
    transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
    transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
    color: #bbb;
}

.form-focus.select-focus .focus-label {
    /*opacity: 1;
	font-weight: 300;
	top: -18px;
	font-size: .8rem;
	z-index: 1;*/
    opacity: 1;
    font-weight: 500;
    top: -32px;
    font-size: .7rem;
    line-height: 1.4;
    z-index: 1;
    background-color: #f9f9f9;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 5px;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
}

.form-focus .select2-container .select2-selection--single {
    border: 1px solid #a5a5a5;
    height: 40px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 48px;
    right: 7px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #ccc transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    height: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #ccc;
    border-width: 0 6px 6px;
}

.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
    padding-left: 12px;
    padding-top: 10px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #676767;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}

.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #fc6075;
}

.form-focus .ui.selection.dropdown {
    height: 40px;
    width: 100%;
    /*min-width: 8em;*/
    min-width: 6em;
    /*padding: 15px 30px 10px 10px;*/
    padding: 12px 12px 6px;
    border-radius: 10px;
}

.form-focus .ui.search.selection.dropdown>input.search {
    height: 100%;
    font-size: 1rem;
    line-height: 1.5;
    padding: 15px 30px 10px 10px;
}

.form-focus .ui.search.dropdown>.text {
    font-size: 1rem;
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
    font-weight: 600;
    color: #114984;
}

.form-focus .ui.search.dropdown>.default.text {
    color: #333;
    opacity: 0.8;
    font-size: .8rem;
}

.form-focus .ui.selection.dropdown>.dropdown.icon {
    top: 12px;
}


/*-----------------
	Nav Tabs
-----------------------*/

.nav-tabs .nav-link {
    border-radius: 0;
}

.nav-tabs>li>a {
    margin-right: 0;
    color: #888;
    border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
    color: #000;
}

.nav-tabs.nav-justified>li>a {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: .8rem 1rem;
    font-weight: 600;
    margin-bottom: 0;
}

.nav-tabs.nav-justified>li>a:hover,
.nav-tabs.nav-justified>li>a:focus {
    border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
    border-color: transparent;
}

.nav-tabs.nav-tabs-solid>li>a {
    color: #000;
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
    background-color: #0c4a85;
    border-color: #0c4a85;
    color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
    border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
    border-radius: 50px;
}

.nav-tabs-justified>li>a {
    border-radius: 0;
    margin-bottom: 0;
}

.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
    border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid>li>a {
    border-color: transparent;
}

.tab-content {
    padding-top: 20px;
}

.sign-out {
    transform: rotate(-90deg);
}


/*-----------------
	Header
-----------------------*/

.header {
    background: #25aae1;
    border-bottom: 1px solid #ededed;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1002;
}

.header .header-left {
    float: left;
    height: 60px;
    padding: 0 15px;
    position: relative;
    text-align: center;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}

.count-page .header .header-left {
    width: 230px;
}

.header .header-left .logo {
    display: inline-block;
    line-height: 58px;
}

.header .header-left .logo .logo-small {
    display: none;
    max-width: 50px;
}

.header .header-left .logo img {
    max-height: 50px;
    padding: 4px;
    background-color: #fff;
    border-radius: 5px;
}

#toggle_btn {
    float: left;
    height: 60px;
    line-height: 70px;
}

.bar-icon {
    display: inline-block;
    width: 20px;
}

.bar-icon span {
    background-color: #fff;
    border-radius: 500px;
    display: block;
    float: left;
    height: 2px;
    margin-bottom: 5px;
    width: 21px;
}



.bar-icon span:nth-child(3) {
    margin-bottom: 0;
}

.header .navbar-nav .badge {
    position: absolute;
    right: 7px;
    top: 4px;
}

.header .dropdown-menu>li>a {
    position: relative;
}

.header .dropdown-toggle::after {
    display: none;
}

.header .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    content: '';
    height: 8px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 8px;
    vertical-align: 2px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.page-title-box {
    border-radius: 0;
    float: left;
    height: 60px;
    margin-bottom: 0;
    padding: 20px;
}

.page-title-box h3 {
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 0;
}

.user-menu {
    float: right;
    margin: 0;
    margin-top: -1px;
    position: relative;
    z-index: 99;

}

.user-menu.nav>li>a {
    color: #fff;
    font-size: 15px;
    line-height: 60px;
    padding: 0 15px;
    height: 60px;
    position: relative;
    border-left: 1px solid rgba(255, 255, 255, 0.4)
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
}

.flag-nav img {
    margin-right: 5px;
}

.flag-nav .dropdown-menu {
    max-height: 150px;
    min-width: 120px;
    overflow-y: auto;
}

.flag-nav .dropdown-menu .dropdown-item {
    padding: 5px 15px;
}

.user-img {
    display: inline-block;
    position: relative;
}

.main-drop .user-img img {
    border-radius: 50%;
    width: 30px;
}

.user-img .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    margin: 0;
    position: absolute;
    right: 0;
    width: 10px;
}

.user-menu .user-img .status {
    bottom: 12px;
}

.status {
    background-color: #263238;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.status.online {
    background-color: #55ce63;
}

.status.offline {
    background-color: #f62d51;
}

.status.away {
    background-color: #faa937;
}

.main-drop .dropdown-menu {
    min-width: 130px;
    padding: 0;
}

.main-drop .dropdown-menu a {
    padding: .8rem 1.5rem
}


/*-----------------
	Notifications
-----------------------*/

.notifications {
    padding: 0;
}

.notifications .noti-content {
    height: 290px;
    width: 350px;
    overflow-y: auto;
    position: relative;
}

.notifications .notification-heading {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.45;
    color: #616161;
}

.notifications .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #bdbdbd;
}

.notification-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.notifications ul.notification-list>li {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list>li:last-child {
    border-bottom: none;
}

.notifications ul.notification-list>li a {
    display: block;
    padding: 12px;
    border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
    background-color: #fafafa;
}

.notifications ul.notification-list>li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}

.notifications .media {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5;
}

.notifications .media:last-child {
    border-bottom: none;
}

.notifications .media a {
    display: block;
    padding: 12px;
    border-radius: 2px;
}

.notifications .media a:hover {
    background-color: #fafafa;
}

.notifications .media-list .media-left {
    padding-right: 8px;
}

.notification-list .status-toggle {
    float: right;
}

.topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
}

.topnav-dropdown-footer {
    border-top: 1px solid #eee;
}

.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
    color: #f12a00;
    float: right;
    font-size: 11px;
    text-transform: uppercase;
}

.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333;
}

.user-menu.nav>li>a .badge {
    color: #fff;
    font-weight: 600;
    position: absolute;
    right: 3px;
    top: 6px;
}

.user-menu.nav>li>a .badge {
    background-color: #f12a00;
}

.user-menu.nav>li>a>i {
    font-size: 20px;
    line-height: 60px;
}

.noti-details {
    color: #989c9e;
    margin-bottom: 0;
}

.noti-title {
    color: #333;
}

.noti-time {
    margin: 0;
}

.avatar {
    background-color: #aaa;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    margin: 0 10px 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 38px;
    position: relative;
    white-space: nowrap;
}

.avatar:hover {
    color: #fff;
}

.avatar.avatar-xs {
    width: 24px;
    height: 24px
}

.avatar>img {
    border-radius: 50%;
    display: block;
    overflow: hidden;
    width: 100%;
}


/*-----------------
	Sidebar
-----------------------*/

.sidebar {
    background-color: #fff;
    border-right: 1px solid #ddd;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 60px;
    transition: all 0.2s ease-in-out 0s;
    width: 230px;
    z-index: 1001;
    padding: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.sidebar.opened {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.sidebar-inner {
    height: 100%;
    transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu {
    padding: 10px 0;
}

.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.sidebar-menu li a {
    color: #333;
    display: block;
    font-size: 0.8rem;
    height: auto;
    padding: 0 20px;
    font-weight: 600;
}

.sidebar-menu li a:hover {
 
    color: #ef4036;
    background-color: #f5f5f5;
    text-decoration: none;
}

.sidebar-menu li.active a,
.sidebar-menu li.submenu>a.active {
    background-color: #ef4036;
    color: #fff;
    font-weight: 600;
}

.menu-title {
    color: #333;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
    font-weight: 600;
}

.menu-title>i {
    float: right;
    line-height: 40px;
}

.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}

.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
    color: #fff;
    text-decoration: underline;
}

.sidebar-menu ul ul a.active:hover {
    color: #f12a00;
}

.mobile-user-menu {
    color: #fff;
    display: none;
    float: right;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 60px;
    z-index: 10;
}

.mobile-user-menu>a {
    color: #333;
    padding: 0;
}

.mobile-user-menu a:hover {
    color: #333;
}

.mobile-user-menu>.dropdown-menu {
    min-width: 130px;
}

.mobile-user-menu>.dropdown-menu>a {
    line-height: 23px;
}

.profile-rightbar {
    display: none !important;
    color: #bbb;
    font-size: 26px;
    margin-left: 15px;
}

.mobile_btn {
    display: none;
    float: left;
}

.sidebar .sidebar-menu>ul>li>a span {
    transition: all 0.2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
    margin-left: auto;
}

.sidebar-menu ul ul a {
    display: block;
    font-size: .8rem;
    padding: 5px 10px 5px 40px;
    position: relative;
}

.sidebar-menu ul ul a i {
    font-size: .8rem !important;
    width: 15px !important;
}

.sidebar-menu ul ul {
    display: none;
}

.sidebar-menu ul ul ul a {
    padding-left: 70px;
}

.sidebar-menu ul ul ul ul a {
    padding-left: 90px;
}

.sidebar-menu>ul>li {
    position: relative;
}

.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: 'FontAwesome';
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 20px;
}

.sidebar-menu .menu-arrow:before {
    content: "\f105";
}

.sidebar-menu li a.subdrop .menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.noti-dot:before {
    content: '';
    width: 5px;
    height: 5px;
    border: 5px solid #ff9b44;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #ff9b44;
    z-index: 10;
    position: absolute;
    right: 37px;
    top: 15px;
}

.noti-dot:after {
    content: '';
    border: 4px solid #ff9b44;
    background: transparent;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    height: 24px;
    width: 24px;
    -webkit-animation: pulse 3s ease-out;
    -moz-animation: pulse 3s ease-out;
    animation: pulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: 8px;
    right: 30px;
    z-index: 1;
    opacity: 0;
}

.sidebar-menu ul ul a .menu-arrow {
    top: 6px;
}

.sidebar-menu a {
    line-height: 1.3;
    transition: unset;
    -moz-transition: unset;
    -o-transition: unset;
    -ms-transition: unset;
    -webkit-transition: unset;
}

.sidebar-menu>ul>li>a {
    padding: 8px 12px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    border-top: 1px solid #ddd;

}

.sidebar-menu ul li a i {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    vertical-align: middle;
    width: 20px;
    transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
    font-size: 16px !important;
    margin-right: 0;
    text-align: right;
    width: auto;
}

.sidebar-menu li a>.badge {
    color: #fff;
}

.slide-nav .sidebar {
    margin-left: 0;
}

.sidebar-overlay {
    display: none;
    position: initial;
    z-index: 90;
    height: 100%;
    left: 0;
    top: 60px;
    width: 100%;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.6);
}

.sidebar-overlay.opened {
    display: block;
}

.sidebar-menu.opened {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    margin-left: 0;
}

html.menu-opened {
    overflow: hidden;
}

html.menu-opened body {
    overflow: hidden;
}


/*-----------------
	Content
-----------------------*/

.page-wrapper {
    padding-top: 60px;
    padding-bottom: 20px;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.myaccount-page .page-wrapper,
.admin-page .page-wrapper {
    margin-left: 230px;
    padding-bottom: 60px;
}

.sharescreen {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.myaccount-page .page-wrapper>.content,
.admin-page .page-wrapper>.content {
    padding: 20px;
    padding-bottom: 30px;
}

.page-header {
    margin-bottom: 1.2rem;
}

.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}

.page-header .breadcrumb a {
    color: #333;
}

.page-title {
    color: #1f1f1f;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.page-sub-title {
    color: #565656;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
}


/*Homepage*/

.home-conetnt {
    padding: 80px 0 0;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin-top: -130px;
    margin-bottom: -20px;
    background-image: url(../img/home/mumbai.png);
    background-repeat: repeat-x;
    background-size: 30%;
    background-position: center bottom;
}

.product-row {
    margin: 15px auto 50px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product-row .display-box {
    position: relative;
    width: calc(100% / 7 - 18px);
    margin-right: 20px;
    cursor: pointer;
    background-color: #fff;
    padding: 1rem;
    position: relative;
    margin-bottom: 2rem;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
    text-align: center;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
}

.product-row .display-box:hover {
    transform: scale(1.05);
}

.product-row .display-box:nth-child(7n),
.product-row .display-box:last-child {
    margin-right: 0;
}

.display-box a {
    display: block;
}

.display-box a:hover {
    text-decoration: none;
}




.display-box .display-text {
    margin-top: 10px;
    text-align: center;
    background-color: #ef4036;
    border: 1px solid #ef4036;
    padding: .5rem 1rem;
    border-radius: 10px;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

.display-box:hover .display-text {
    background-color: #fff;
    border: 1px solid #ef4036;
}

.display-box .display-text h3 {
    font-size: .7rem;
    color: #fff;
    font-weight: 600;
}

.display-box:hover .display-text h3 {
    color: #ef4036;
}

.display-box .display-img {
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    text-align: center;
    background-color: #FFF;
    border-radius: 10px;
    /*box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);*/
}

.display-box:hover .display-img {
    /*transform: scale(1.05);*/
    text-align: center
}

.display-box .display-img img {
    max-width: 90%
}


/*Quote Form*/

.shadowbox {
    box-shadow: 0 2px 22px 0 rgba(70, 90, 207, 0.16) !important;
}

.card {
    border: 1px solid #ededed;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
}

.card-body {
    padding: 1rem;
}

.card.cardshadow {
    -webkit-box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
}

.card-title {
    color: #1f1f1f;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.card-header {
    /*background-color: #f9f9f9;*/
    background-color: #25aae1;
    margin-bottom: .5rem;
}

.proposalform .card-header {
    background-color: #f9f9f9;
}

.card-header,
.card-header:first-child {
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-header h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    text-transform: uppercase;
}

.proposalform .card-header h3 {
    color: #25aae1;
    font-weight: 700;
}

.card-header h3.ui.ribbon.label {
    font-size: 1rem !important;
    border-radius: 0 !important;
}

.card-header h3.ui.ribbon.label {
    background-color: #39b54a !important;
}

.card-footer {
    background-color: #fff;
}

.blue-border {
    /*border-color: #0c4a85 !important;*/
    border: 1px solid rgba(0, 0, 0, .125);
}

.red-border {
    border-color: #f12a00 !important;
}

.topsearchbar {
    border: 2px solid #25aae1;
}

.quote-serchbox .form-group {
    margin-bottom: 0;
}

.quote-serchbox .form-focus .form-control {
    font-size: 1.2rem;
}

.quickquote-option {
    height: 50px;
    padding: 5px 20px;
}

.quickquote-option .ui.checkbox label,
.ui.checkbox+label {
    color: #000;
    font-size: 1.2rem;
    padding-left: 25px;
}

.quoteproicon img {
    background-color: #fcb040;
    border-radius: 50%;
    padding: 0;
    width: 100%;
    max-width: 70px;
    box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
}

.quoteproicon h1 {
    font-size: 28px;
    line-height: 1;
    font-weight: 400;
}

.quoteproicon h1 span {
    display: block;
    font-weight: 800;
}

.searchbar {
    /*margin-bottom: 0;*/
    /*background-color: #dae1f2;*/
}

.productbar {
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
}

.productbar .errorbox {
    display: inline-block;
    text-align: center;
    background-color: #f12a00;
    color: #fff;
    padding: .1rem .5rem;
    border-radius: 10px;
    font-size: .7rem;
}

.instabs [type="radio"]:checked,
.instabs [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
}

.checkbox-instab:checked+label,
.checkbox-instab:not(:checked)+label {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0;
    text-align: center;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    /*-webkit-text-stroke: 1px #fff;
	text-stroke: 1px #fff;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;*/
    color: #ef4036;
    border: 1px solid #ef4036;
}

.checkbox-instab:not(:checked)+label {
    background-color: #353746;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.checkbox-instab:checked+label {
    background-color: #ef4036;
    background: linear-gradient( #ef4036, #c2271e);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-color: #ef4036;
}

.checkbox-instab:not(:checked)+label:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.checkbox-instab:checked+label::before,
.checkbox-instab:not(:checked)+label::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: linear-gradient( #0c4a85, #1d6dc1);
    z-index: -1;
}

.checkbox-instab:checked+label span,
.checkbox-instab:not(:checked)+label span {
    position: relative;
    display: block;
}

.checkbox-instab:checked+label span {
    color: #fff;
}

.checkbox-instab:checked+label span.instab-icon,
.checkbox-instab:not(:checked)+label span.instab-icon {
    display: none !important;
}

.checkbox-instab:checked+label span::before,
.checkbox-instab:not(:checked)+label span::before {
    position: absolute;
    content: attr(data-hover);
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    -webkit-text-stroke: transparent;
    text-stroke: transparent;
    -webkit-text-fill-color: #fff;
    text-fill-color: #fff;
    color: #fff;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    transition: max-height 0.3s;
}

.checkbox-instab:not(:checked)+label span::before {
    max-height: 0;
}

.checkbox-instab:checked+label span::before {
    max-height: 100%;
}

.checkbox-instab:not(:checked)+label {
    background-color: #fff;
    /*-webkit-text-stroke: 1px #1f2029;
	text-stroke: 1px #1f2029;*/
    box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}

.productbar .ui.checkbox label,
.productbar .ui.checkbox+label {
    color: #39b54a !important;
}

.productbar .ui.radio.checkbox input:checked~.box:after,
.productbar .ui.radio.checkbox input:checked~label:after {
    background-color: #39b54a !important;
}

.quotebtn {
    padding: 20px 35px;
    font-size: 1.2rem;
    min-width: 250px;
}


/*-----------------
	Footer
-----------------------*/

.footer {
    /*border-top: 1px solid #dee2e6;*/
    border-top: 1px solid rgba(34, 36, 38, .15);
    border-bottom: 1px solid rgba(34, 36, 38, .15);
    background-color: #fff;
    /*position: fixed;*/
    position: relative;
    z-index: 99;
    width: 100%;
    left: 0;
    bottom: 0;
}

.homepage .footer,
.footer-fixed {
    position: fixed;
}

.dashboard-footer .footer {
    /*position: fixed;*/
    background-color: #fff;
}

.copyright_a p {
    font-size: 0.600rem !important;
    line-height: 1.5 !important;
    margin: 0 !important;
    color: #263F65 !important; 
    text-align: center !important;

}
.footer_a{
  

    border-top: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.15);
    background-color: #fff !important;
    position: relative;
    z-index: 99;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: center !important;
    padding: 10px 0px !important;
}



.copyright {
    /*background: url(../img/bg/footer-bg.svg) no-repeat top center;*/
    background-size: cover;
    /*background-color: #114984;*/
    padding: 10px 0;
    color: #52525e;
    font-weight: 500;
    text-align: center;
}

.dashboard-footer .copyright {
    padding: 5px 0;
    text-align: right;
}

.dashboard-footer .copyright .container {
    max-width: 100%;
}

.copyright p {
    font-size: 0.600rem;
    line-height: 1.5;
    margin: 0;
}

.dashboard-footer .copyright p {
    font-size: 0.600rem;
}

.copyright p a {
    color: #52525e;
}

.copyright p a:hover,
.copyright-links a:hover {
    text-decoration: underline;
}

.row:before,
.row:after {
    display: none !important;
}


/*-----------------
	Quote Page
-----------------------*/

.quotes-topinfo {
    background-color: #f9f9f9;
    color: #114984;
}

.sharescreen .quotes-topinfo {
    border-radius: 10px;
}

.quotes-topinfo .quotes-box {
    border-right: 1px solid #114984;
}

.quotes-topinfo .quotes-box:last-child {
    border-right: 0;
}

.quotes-topinfo h4,
.quotes-editoption h4,
.proposal-box h4 {
    font-size: 1rem;
    font-weight: 600;
}

.quotes-editoption h4 {
    font-size: 1.1rem;
}

.quotes-topinfo h4 span.text-muted,
.quotes-editoption h4 span.text-muted,
.proposal-box h4 span.text-muted {
    font-weight: 400;
}

.quotes-topinfo .actionbtn {
    position: absolute;
    right: 15px;
    top: 0;
    width: 100%;
    font-size: 1rem;
    line-height: 2;
}

.quotes-editoption {
    /*background-color: #fff;*/
    padding: 0;
    /*box-shadow: 20px 24px 108px -20px rgba(0, 0, 0, 0.07), 0 0px 34px -10px rgba(195, 197, 212, 0.57), 0 2px 64px 0 rgba(70, 90, 207, 0.16);*/
}

.quotes-editoption .option-box {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #d7d7d7;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
    color: #114984;
    border-radius: 10px;
}

.quotes-editoption .option-box:last-child {
    border-bottom: 0;
}


/*.quotes-editoption .option-box::before {
	content: '';
	width: 100%;
	height: 2px;
	position: absolute;
	left: 0;
	top: -1px;
	background-color: #39b54a ;
	opacity: 0;
}*/

.quotes-editoption .option-box:hover {
    border: 1px solid #114984;
}


/*.quotes-editoption .option-box:hover::before {
	opacity: 1;
}*/

.quotes-editoption .option-box .edit-btn {
    float: right;
}

.quotes-editoption .option-box .edit-btn:after {
    content: '+ Open';
    padding: 0 1px;
    color: #fff;
}

.quotes-editoption .option-box .edit-btn:hover:after {
    color: #0e8943;
}

.quotes-editoption .option-box .edit-btn[aria-expanded="true"]:after {
    content: '- Close';
    padding: 0 1px;
}

.option-box.idv-option-box .actionbtn {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.option-box.covers-option-box .ui.multiple.dropdown>.label {
    background-color: #55ce63;
    color: #fff;
}

.option-box.covers-option-box .ui.multiple.dropdown>.label>.delete.icon {
    font-family: 'FontAwesome';
}

.quotes-editoption .option-box .form-group:last-child {
    margin-bottom: 0;
}

.quotes-editoption .option-box .quotes-update-btn {
    padding: .5rem 2rem;
    font-size: 1.5rem;
    line-height: 2;
    border-radius: 28px;
    width: 100%;
}


/*Quotes List*/

.quoteslist {
    padding: 1rem;
    padding-right: 0;
}

.updating-quoteslist {
    filter: grayscale(90%);
    position: relative;
}

.updating-quoteslist-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .8);
    z-index: 1;
    border-radius: 10px;
}

.updating-quoteslist-btns {
    position: absolute;
    z-index: 2;
    text-align: center;
    margin: 1rem 0;
    width: 100%;
}

.updating-quoteslist-btns .btn {
    min-width: 180px;
    margin: 1rem 0;
}

.quoteitem {
    margin-bottom: 1rem;
    background-color: #fff;
    box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
    border-color: #d7d7d7 !important;
    border-radius: 10px;
}

.quoteitem:last-child {
    margin-bottom: 0;
}

.quoteitem:hover {
    /*box-shadow: 0 2px 22px 0 rgba(70, 90, 207, 0.16) !important;*/
    /*background-color: #dae1f2;*/
    /*background-color: rgba(218,225,242,.6);*/
    background-color: #fff;
    border: solid 1px #114984!important;
}

.quoteitembox {}

.insurer-box img {
    width: 100%;
    max-width: 100px;
    border: 1px solid #dee2e6;
    padding: 2px 5px;
    background: #fff;
    border-radius: 4px;
}

.idvdisc-box {
    padding-left: 0;
}

.idvdisc-box h4 {
    font-size: 1rem;
}

.idvdisc-box h4 .text-muted {
    font-size: .8rem;
}

.addons-box .btn {
    font-size: 0.7rem;
    width: auto;
    margin: .2rem;
    line-height: 1rem;
    padding: 0.2rem 0.5rem;
    /*min-width: 100px;*/
}

.premium-box .premiumamt {
    line-height: 1;
    font-size: 1.6rem;
    font-weight: 600;
    color: #114984;
}

.premium-box .premiumamt span {
    font-weight: 500;
}

.premium-box .premiumamt span,
.addonpremium span {
    font-size: 0.6rem;
    color: #6c757d;
}

.buypolicy-box .btn {
    width: 100%;
    line-height: 1;
    padding: 15px 20px;
}

.selectquote-box {
    text-align: center;
    box-shadow: 0 2px 22px 0 rgba(70, 90, 207, 0.16);
    padding: 1rem 0.5rem;
    border: 1px solid #d7d7d7;
    background-color: #fff;
    border-radius: 10px;
}

.selected-addons-list {
    margin-bottom: 0;
}

.selected-addons-list li {
    display: inline-block;
    border: 1px solid #aeaeae;
    /*color: #848484;*/
    font-size: .7rem;
    border-radius: 10px;
    padding: .15714286em .78571429em;
    margin: .14285714rem .28571429rem .14285714rem 0;
    font-weight: 500;
    background-color: rgba(218, 225, 242, .6);
}

.selected-addons-list li a {
    color: #fff;
    opacity: 0.5;
    margin-left: 2px;
}

.selected-addons-list li:hover a {
    opacity: 1;
}

.idv-content {
    /*background-color: rgba(218,225,242,.6);*/
    padding: 1rem 0;
    margin-top: 1rem;
    border-top: 1px solid #d7d7d7;
}

.idv-content .min-value,
.idv-content .max-value {
    font-size: .9rem;
}

.idv-content .idv-value {
    font-size: 1.5rem;
}

.idv-content input[type=text] {
    max-width: 80%;
    display: inline;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
}

.idv-content input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

.idv-content input[type=range]:focus {
    outline: none;
}

.idv-content input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    background: rgb(190, 190, 190);
    border-radius: 25px;
}

.idv-content input[type=range]::-webkit-slider-thumb {
    height: 20px;
    width: 39px;
    border-radius: 7px;
    background: #39b54a;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.6px;
}

.idv-content input[type=range]:focus::-webkit-slider-runnable-track {
    background: rgb(190, 190, 190);
}

.idv-content input[type=range]::-moz-range-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    animate: 0.2s;
    background: rgb(190, 190, 190);
    border-radius: 25px;
}

.idv-content input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 39px;
    border-radius: 7px;
    background: #39b54a;
    cursor: pointer;
}

.idv-content input[type=range]::-ms-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
}

.idv-content input[type=range]::-ms-fill-lower {
    background: #ac51b5;
    border: 0px solid #000101;
    border-radius: 50px;
}

.idv-content input[type=range]::-ms-fill-upper {
    background: #ac51b5;
    border: 0px solid #000101;
    border-radius: 50px;
}

.idv-content input[type=range]::-ms-thumb {
    border: 0px solid #000000;
    height: 20px;
    width: 39px;
    border-radius: 7px;
    background: #65001c;
    cursor: pointer;
}

.idv-content input[type=range]:focus::-ms-fill-lower {
    background: #ac51b5;
}

.idv-content input[type=range]:focus::-ms-fill-upper {
    background: #ac51b5;
}

.addonslist li {
    margin-bottom: 1rem;
}


/*Policy Details*/

.policy-details {}

.policy-details .pd-leftinfo {
    /*background-color:rgba(0, 168, 204, 0.075);*/
    background-color: rgba(218, 225, 242, .6);
    padding-bottom: 1rem;
    border-right: 1px solid #dee2e6;
}

.policy-details .pd-leftinfo .option-box {
    padding: 1rem 0;
    border-bottom: 1px dashed #0c4a85;
    position: relative;
    text-align: center;
}

.policy-details .pd-leftinfo .option-box:last-child {
    border-bottom: 0;
}

.policy-details .pd-leftinfo .insurer-premium .premiumamt {
    line-height: 1.5;
    font-size: 1.5rem;
    font-weight: 600;
}

.policy-details .pd-leftinfo .insurer-premium .premiumamt span {
    font-weight: 500;
    font-size: 0.6rem;
    color: #6c757d;
}

.policy-details .pd-leftinfo .insurer-premium .buybtn {
    width: 100%;
    margin: 1rem 0 0;
    line-height: 2;
    font-size: 1.2rem;
}

.policy-details .pd-rightinfo {
    padding-top: 1rem;
    background-color: #fff;
}

.policy-details .nav-tabs .nav-link {
    padding: .8rem 1rem;
    font-weight: 600;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.policy-details .tab-content {
    border: 1px solid #dee2e6;
    border-top: 0;
    padding: 1rem;
    margin-bottom: 1rem;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.premium-breakup-content {
    padding: 1rem 3rem;
}

.premium-breakup-content ul {
    border-bottom: 1px dashed #0c4a85;
    padding: 0 1rem;
}

.premium-breakup-content ul:first-child {
    border-top: 1px dashed #0c4a85;
    padding-top: .5rem;
}

.premium-breakup-content ul.finalpremiumbox {
    background-color: #39b54a;
    color: #fff;
    border-radius: 10px;
    padding: 1rem;
    border-color: transparent;
}

.premium-breakup-content ul li {
    margin-bottom: .5rem;
    position: relative;
}

.premium-breakup-content ul li.sub {
    padding-left: 10px;
}

.premium-breakup-content ul li span:first-child {
    width: 70%;
    display: inline-block;
}

.premium-breakup-content ul li.sub::before {
    content: '- ';
    top: -1px;
    position: absolute;
    left: 0;
}

.premium-breakup-content ul li .finalpremium {
    font-size: 1.5rem;
    font-weight: 600;
}

.coverage-details-content ul li {
    margin-bottom: .5rem;
    padding-left: 15px;
    position: relative;
}

.coverage-details-content ul li .fa {
    position: absolute;
    left: 0;
    top: 2px;
}

.coverage-details-content ul.recomendedcovers-list li {
    padding-left: 0;
}

.coverage-details-content ul.recomendedcovers-list li .subtext {
    padding-left: 2rem;
}

.garagetable h5 {
    margin-bottom: .5rem;
    font-weight: 600;
}


/*-----------------
	Quick Quotes Page
-----------------------*/

.shareIcons {
    position: fixed;
    z-index: 999;
    right: 0;
    top: 155px;
}

.shareIcons>li {
    position: relative;
    margin-bottom: 5px;
    width: 170px;
    right: -130px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.shareIcons>li>a {
    font-size: .8rem;
    color: #fff;
    background: #0e8943;
    position: relative;
    z-index: 99;
    padding: 12px 0 12px 40px;
    display: block;
    text-decoration: none;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.shareIcons>li:hover {
    right: 0;
}

.shareIcons>li:hover a {
    background-color: #0e8943;
}

.shareIcons>li>a:before {
    content: " ";
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    line-height: 40px;
    text-align: center;
}

.shareIcons>li.sms>a:before {
    content: "\f10b"
}

.shareIcons>li.whatsapp>a:before {
    content: "\f232"
}

.shareIcons>li.email>a:before {
    content: "\f003"
}

.proposal-shareIcons {
    top: 75px;
}

.proposal-shareIcons>li.edit>a:before {
    content: "\f040"
}

.proposal-shareIcons>li.cancel>a:before {
    content: "\f00d"
}

.proposal-shareIcons>li.forward>a:before {
    content: "\f064"
}

.proposal-shareIcons>li.download>a:before {
    content: "\f019"
}


/*-----------------
	Proposal Page
-----------------------*/

.customerdetail-infobar {
    background-color: #f9f9f9;
    color: #114984;
}

.customerdetail-infobar h4 {
    font-size: 1rem;
    font-weight: 600;
}

.customerdetail-infobar .customerdetail-box {
    border-right: 1px solid #114984;
}

.customerdetail-infobar .customerdetail-box:last-child {
    border-right: 0;
}

.customerdetail-box h3 {
    line-height: 1.1;
    font-size: 1.6rem;
}

.customerdetail-box .selectedinsurer img {
    max-width: 65px;
    border-radius: 5px;
    padding: 0.1rem;
    background: #fff;
    margin: 0.1rem 0 0 0;
    border: 1px solid #114984;
}

.customerdetailform-sidebar {
    padding: 0rem;
}

.customerdetailform-sidebar .totalpremium-box {
    padding: 1rem;
    position: relative;
    border: 1px solid #d7d7d7;
    position: relative;
    background-color: #39b54a;
    box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75);
    color: #fff;
    border-radius: 10px;
}

.customerdetailform-sidebar .totalpremium-box ul li {
    border-bottom: 1px dashed #d7d7d7;
    padding: .8rem 0;
}

.customerdetailform-sidebar .totalpremium-box ul li.finalpremium {
    background-color: #39b54a;
    margin-top: -1px;
    padding: 1rem;
    border-color: transparent;
    text-align: center;
    color: #fff;
}

.customerdetailform-sidebar .totalpremium-box ul li.finalpremium em {
    font-style: normal;
}

.customerdetailform-sidebar .totalpremium-box ul li.finalpremium span {
    display: block;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 600;
}


/*customerdetail Form*/

.customerdetailform-container {
    margin-right: -10px;
}

.customerdetailform-stepbox {
    margin-bottom: 1rem;
}

.customerdetailform-stepbox h3.step-title {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 1rem;
    font-weight: 600;
    color: #114984;
}

.customerdetailform-stepbox h3.step-title .badge {
    margin-right: .5rem;
    font-size: 70%;
    padding: .25em .6em;
    position: relative;
    top: -2px;
}

.completeddetailsbox {
    position: relative;
}

.completeddetailsbox h3 {
    font-weight: 600;
    color: #114984;
}

.completeddetailsbox h4 {
    font-weight: 600;
}

.completeddetailsbox h5 {
    margin-bottom: 1.2rem;
    font-size: .9rem;
}

.completeddetailsbox .actionbtn {
    position: absolute;
    right: 1.25rem;
    top: 0;
    z-index: 1;
}

.completeddetailsbox .vehicleinfowrap .actionbtn {
    top: 1.25rem;
}

.completeddetailsbox .filledinfo {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.completeddetailsbox .filledinfo li {
    padding: 1rem;
    /*border-top:  1px solid rgba(174, 174, 174, 0.3);*/
}

.completeddetailsbox .filledinfo li:nth-child(odd) {
    /*background-color: rgba(218,225,242,.6);*/
    background-color: #f9f9f9;
    border-radius: 10px;
}

.completeddetailsbox .filledinfo li:nth-child(even) {
    background-color: #fff;
}

.completeddetailsbox .filledinfo li .row>div {
    border-right: 1px solid rgba(174, 174, 174, 0.6);
}

.completeddetailsbox .filledinfo li .row>div:last-child {
    border-right: 0;
}

.completeddetailsbox .filledinfo li .title {
    /*float: left;
    margin-right: 30px;
    width: 30%;
    color: #666;*/
}

.completeddetailsbox .filledinfo li .text {
    display: block;
    overflow: hidden;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
}

.completeddetailsbox .vehicleinfowrap .vehicleinfo {
    text-align: center;
    /*border: 1px solid rgba(174, 174, 174, 0.6);*/
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    /*background-color: rgba(218,225,242,.6);*/
    background-color: #f9f9f9;
    border-radius: 10px;
}

.completeddetailsbox .vehicleinfowrap .vehicleinfo h5 {
    margin-bottom: 0;
    font-size: 1.2rem;
    line-height: 1.5;
}

.proposal-infobar {
    background-color: #0c4a85;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
}

.proposal-infobar .customerdetail-box {
    border-right: 1px solid #dee2e6;
}

.proposal-infobar .proposaldetail-box:last-child {
    border-right: 0;
}

.proposaldetail-box h3 {
    line-height: 1.1;
    font-size: 1.6rem;
}

.proposaldetail-box .selectedinsurer img {
    max-width: 150px;
    border-radius: 5px;
    padding: 0.1rem;
    background: #fff;
    margin: 0.1rem 0 0 0;
}

.proposal-premium .premium-breakup-content {
    padding: 0;
}

.proposal-premium-notbox {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.proposal-premium-notbox ul li {
    margin-bottom: .3rem;
    font-size: 0.7rem;
    line-height: 1.4;
    list-style: disc;
}

.proposal-posinfobox {
    border-right: 1px solid #dee2e6;
}

.proposal-posinfo {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.proposal-posinfo li .title {
    float: left;
    margin-right: 30px;
    width: 35%;
    color: #333;
}

.proposal-posinfo li .text {
    display: block;
    overflow: hidden;
    font-weight: 600;
}

.proposal-posinfo li {
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
}

.declaration-box ul {
    margin-left: 1rem;
}

.declaration-box ul li {
    list-style: decimal;
    margin-bottom: .5rem;
}

.digit-group {
    margin-bottom: 2rem;
}

.digit-group input {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #a5a5a5;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    color: #114984;
    margin: 0 2px;
}

.digit-group input::-webkit-inner-spin-button,
.digit-group input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.digit-group input:focus {
    border-color: #39b54a;
}

.digit-group .splitter {
    padding: 0 5px;
    color: #114984;
    font-size: 24px;
}

.paymentoption {
    text-align: center;
}

.paymentoption li {
    display: inline-block;
    margin: 5px;
}

.paymentoption li label span {
    border: 1px solid #114984;
    display: block;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    float: left;
    padding: 10px;
    position: relative;
    padding-left: 40px;
    color: #114984;
    text-align: left;
    font-size: .850rem;
    font-weight: 600;
    cursor: pointer;
}

.paymentoption li label input[type=radio] {
    display: none;
}

.paymentoption li label input[type=radio]+span:before {
    content: "\f10c";
    font-family: FontAwesome;
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 15px;
    font-size: 20px;
}

.paymentoption li label input[type=radio]:checked+span {
    background-color: #114984;
    color: #fff;
}

.paymentoption li label input[type=radio]:checked+span:before {
    content: "\f00c";
}

.paymentoption-text .finalpremium {
    text-align: center;
}

.paymentoption-text .finalpremium em {
    font-style: normal;
}

.paymentoption-text .finalpremium span {
    display: block;
    font-size: 2.5rem;
    line-height: 1.5;
    font-weight: 600;
    margin: .3rem 0;
}

.walletbox .card {
    background-color: #0c4a85;
    background: linear-gradient( #0c4a85, #1d6dc1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-color: #0c4a85;
    color: #fff;
}

.walletbox .wallet-icon img {
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
}

.walletbox .wallet-text {
    padding: .3rem 0;
}

.walletbox .wallet-text h5 {
    margin-bottom: .2rem;
}

.walletbox .wallet-text h2 {
    font-weight: 700;
}

.paymentoption-box .termstext {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 10px;
}

.paymentoption-box .termstext li {
    margin-bottom: .5rem;
}


/*Payment Success Page*/

.payment-success {
    padding: 1rem;
}

.payment-success .tophead {
    text-align: center;
    margin-bottom: 3rem;
}

.payment-success .tophead .success-icon {
    font-size: 80px;
    line-height: 80px;
    color: #39b54a;
    margin-bottom: 1rem;
}

.payment-success.failed .tophead .success-icon {
    color: #f12a00;
}

.payment-success .tophead h2 {
    font-weight: 400;
}

.payment-success .tophead h2 span {
    font-weight: 700;
}

.payment-success .tophead h3 {
    color: #39b54a;
}

.payment-success.failed .tophead h3 {
    color: #f12a00;
}

.payment-success .policynobox {
    background-color: #0c4a85;
    background: linear-gradient( #0c4a85, #1d6dc1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-color: #0c4a85;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}

.payment-success .policynobox h4 {
    margin-bottom: .4rem;
}

.payment-success .policynobox h3 {
    font-weight: 700;
}

.payment-success .premiumpaid {
    text-align: center;
    margin-bottom: 1rem;
}

.payment-success .premiumpaid span {
    display: block;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 600;
    margin: .3rem 0;
}

.payment-success .infolist {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.payment-success .infolist li .title {
    color: #333;
    float: left;
    margin-right: 30px;
    width: 30%;
}

.payment-success .infolist li .text {
    display: block;
    font-weight: 600;
    font-size: .9rem;
    overflow: hidden;
}

.payment-success .infolist li {
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    line-height: 1.2;
}

.payment-success .infolist li:last-child {
    margin-bottom: 0;
}


/*-----------------
	Myaccount Pages
-----------------------*/

.latest-update {
    background-color: #fff;
    padding: .4rem 20px;
    margin: -20px -20px 15px -20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.latest-update .labeltext {
    background-color: #f12a00;
    color: #fff;
    padding: .3rem 1rem;
    border-radius: 10px;
}

.latest-update .updatetext {
    color: #0c4a85;
    font-weight: 500;
}

.latest-update .updatetext a {
    color: #0c4a85;
    text-decoration: underline;
}

.latest-update .updatetext span {
    margin-right: 20px;
}

.latest-update .updatetext span:before {
    content: "\f111";
    font-family: FontAwesome;
    display: inline-block;
    margin-right: 5px;
}


/*.dashboard-search .form-control {
	height: 34px;
}*/

.uploadbreakincase-infobox {
    border-right: 1px solid #dee2e6;
}

.uploadbreakincase-infobox:last-child {
    border-right: none;
}

.uploadbreakincase-info {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.uploadbreakincase-info li .title {
    float: left;
    margin-right: 30px;
    width: 30%;
    color: #333;
}

.uploadbreakincase-info li .text {
    display: block;
    overflow: hidden;
    font-weight: 600;
}

.uploadbreakincase-info li {
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
}

.fileuploadbox {
    background-color: rgba(218, 225, 242, .6);
    border: 1px solid #dee2e6;
    padding: 6.25rem 1.25rem;
    width: 100%;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    padding: .5rem 0;
}

.fileuploadbox .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.fileuploadbox .inputfile+label {
    max-width: 80%;
    font-size: 1rem;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.fileuploadbox .inputfile+label figure {
    margin: .5rem 20px;
}

.uploaded-img {
    position: relative;
    width: 100%;
    border: 1px solid #dee2e6;
    padding: 2px;
    background: #fff;
    border-radius: 4px;
    text-align: center;
}

.uploaded-img img {
    max-width: 100%;
    max-height: 62px;
    border-radius: 4px;
    min-height: 62px;
}

.uploaded-img .delete-img {
    background-color: #f12a00;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
    text-align: center;
    line-height: 14px;
    display: inline-block;
    position: absolute;
    right: -7px;
    top: -7px;
    cursor: pointer;
}

.faq-accordion .card {
    border: 0;
    border-bottom: 2px solid #ededed;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
}

.accordion-style-1 .card .card-header {
    border-bottom: 0;
    position: relative;
    background-color: transparent;
    margin-bottom: 0;
}

.accordion-style-1 .card .card-header h5 {
    margin-bottom: 0;
}

.accordion-style-1 .card .card-header h5 a {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.6rem;
    color: #000839;
    display: block;
}

.accordion-style-1 .card .card-header a:not(.collapsed) {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: .8rem;
}

.accordion-style-1 .card .card-header i.fa {
    position: absolute;
    top: 13px;
    right: 20px;
    text-align: center;
    line-height: 24px;
    display: block!important;
    width: auto;
    font-size: 20px;
    transition: transform .3s ease;
}

.accordion-style-1 .card .card-header a:not(.collapsed) i.fa-pencil:before {
    content: "\f055";
}

.accordion-style-1 .card .card-header a:not(.collapsed) i.fa {
    transform: rotate(45deg);
    color: #f12a00;
}

.endorsement-infolist {
    list-style: none;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #dee2e6;
}

.endorsement-infolist li .title {
    color: #333;
    float: left;
    margin-right: 30px;
    width: 35%;
}

.endorsement-infolist li .text {
    display: block;
    font-weight: 600;
    font-size: .9rem;
    overflow: hidden;
}

.endorsement-infolist li {
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    line-height: 1.2;
}

.endorsement-infolist li:last-child {
    margin-bottom: 0;
}

.dash-widget-icon {
    background-color: rgba(18, 63, 109, 0.10);
    border-radius: 100%;
    color: var(--brand-red);
    display: inline-block;
    float: left;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    margin-right: 10px;
    text-align: center;
    width: 60px;
}

.dash-widget-info {
    text-align: center;
}

.dash-widget-info>h3 {
    font-size: 30px;
    font-weight: 600;
}

.dash-widget-info>span {
    font-size: 14px;
}

.generate-invoice-statsinfo .dash-widget-info>h3 {
    margin-bottom: 5px;
    font-size: 24px;
}


/*Customer Details*/

.text-muted.white {
    color: #114984 !important;
}

.proposal-infobar {
    background-color: #ef4036;
    color: #fff;
}

.proposal-infobar .proposal-box {
    border-right: 1px solid #dee2e6;
}

.proposal-infobar .proposal-box:last-child {
    border-right: 0;
}

.proposal-box h3 {
    line-height: 1.1;
    font-size: 1.6rem;
}

.proposal-box .selectedinsurer img {
    max-width: 65px;
    border-radius: 5px;
    padding: 0.1rem;
    background: #fff;
    margin: 0.1rem 0 0 0;
}

.proposalform-sidebar {
    background-color: #39b54a;
    padding: 1rem;
}

.proposalform-sidebar .totalpremium-box {
    padding: 1rem 0;
    position: relative;
    color: #fff;
}

.proposalform-sidebar .totalpremium-box ul li {
    border-bottom: 1px dashed #fff;
    padding: .8rem 0;
}

.proposalform-sidebar .totalpremium-box ul li.finalpremium {

    margin-top: 1rem;
    padding: 1rem;
    border: 1px dashed #fff;
    text-align: center;
}

.proposalform-sidebar .totalpremium-box ul li.finalpremium em {
    font-style: normal;
}

.proposalform-sidebar .totalpremium-box ul li.finalpremium span {
    display: block;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
}

.radius-10 {
    border-radius: 10px;
}


/*Emd Customer Details*/


/*-----------------
	Below two css for notifier(use either one of them)
-----------------------*/

.aboveAll {
    top: 40px !important;
}

.swal2-title {
    font-size: 16px!important;
}

.swal2-icon {
    margin: 0.20em auto 0.60em!important;
}


div.dataTables_wrapper div.dataTables_length select{
      width: 51px !important;
}


