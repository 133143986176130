.login-container {
    font-family: 'Montserrat', sans-serif;
    font-size: .8rem;
    color: #000;
    background-image: url('../img/login/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: contain;
}

a {
    color: #52525a;
}

a:hover {
    color: #52525a;
    text-decoration: underline;
}

.login-container {
    display: block;
    align-items: center;
    
}

.login-inner {
    display: block;
    height: 100vh;
    width: 100%;
}

.login-two-panel {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.login-two-panel>div {
    flex: 1;
}

.login-two-panel>div:first-child {
    display: flex;
    align-items: center;
    /* background: #f7f7f7; */
}

.login-form {
    padding: 2.5rem;
    background: whitesmoke;
    border-radius: 10px;
    margin: 0 40px ;
    color: #52525a;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    width: 100%;
    /* box-shadow: 0px 0px 24px -14px rgba(0, 0, 0, 0.75); */
}

.login-form .login-logo {
    text-align: center;
    color: hsla(0, 0%, 23%, 1);
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.login-form .login-logo img {
    max-width: 280px;
}

.login-form h4 {
    color: #52525a;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 600;
    margin-bottom: .5rem;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.login-form p {
    margin-bottom: 2rem;
}

.login-form .form-control,
.login-form .btn {
    height: 40px !important;
    border-radius: 10px !important;
    border-color: #52525a;
}

.login-form .form-control,
.ui.icon.input>i.icon {
    color: #52525a !important;
}

.login-form .btn {
    color: #fff;
    background-color: #ca3a14;
    border: 1px solid transparent;
    border-radius: 0;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: .9rem;
}

.login-form .btn:hover {
    color: #ca3a14;
    background-color: transparent;
    border: 1px solid #ca3a14;
}

@media (min-width: 769px) {
    /* .login-container {
        background-color: hsla(0, 0%, 96%, 1) !important;
    } */
    .login-form {
        width: 30rem;
    }
    /*.login-two-pane > div:last-child {
        display: block;
    }*/
}

@media (max-width: 568px) {
    .login-container {
        background-color: #fff;
    } 
    .login-form {
        margin: 0 2rem;
    }
}

.captchatext {
    border: 0 !important;
    color: #000 !important;
    font-weight: bold;
    letter-spacing: 10px;
    background-color: lightgrey !important;
    text-align: center !important;
}
